import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, Button, CircularProgress, Avatar, Pagination } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

const SinglePage = () => {
  const { category } = useParams(); // Extract category name from the URL
  const [posts, setPosts] = useState([]); // Initialize with an empty array
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [postsPerPage] = useState(16); // Set the number of posts per page
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initially check if it's a mobile screen

  // Define button colors
  const buttonColors = [
    '#FF5733', // Red
    '#33C4FF', // Blue
    '#28A745', // Green
    '#FF914D', // Orange
    '#F9A825', // Yellow
    '#9C27B0', // Purple
  ];

  useEffect(() => {
    document.title = category + " | RoloeGanga";

    // Check if the meta title already exists
    let metaTitleTag = document.querySelector('meta[name="title"]');
    if (!metaTitleTag) {
      // Create and append the meta title if it does not exist
      metaTitleTag = document.createElement("meta");
      metaTitleTag.name = "title";
      document.head.appendChild(metaTitleTag);
    }
    metaTitleTag.content = category + " | RoloeGanga";
    const fetchPosts = async () => {
      try {
        console.log('Fetching posts for category:', category);

        if (!category) {
          setError('Invalid category');
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const result = await axios.get(
          `https://roloeganga.net/admin/api/category/news/${category}`
        );

        console.log('API Response:', result.data);

        if (result.data && Array.isArray(result.data.news)) {
          const sortedPosts = result.data.news.sort((a, b) => new Date(b.date) - new Date(a.date));
          setPosts(sortedPosts);
        } else {
          setPosts([]);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Failed to load posts');
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [category]);

  const limitTitle = (title) => {
    if (title.length > 35) {
      return title.slice(0, 35) + '...'; // Truncate title if it's too long
    }
    return title;
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) {
      return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    return 'Invalid Date';
  };

  // Calculate the index of the first post and last post for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Handle page change
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: for smooth scrolling
    });
  };
  

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </div>
    );
  }

  if (posts.length === 0) {
    return (
      <div style={{ padding: '20px', textAlign: 'center'}}>
        <Typography variant="h6" color="textSecondary">
        No hay publicaciones disponibles para la categoría. "{category}". Vuelve a consultar más tarde.
        </Typography>
      </div>
    );
  }

  const paddingStyle = isMobile ? '80px 10px 20px' : '80px 100px 20px';

  // Pagination component
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div style={{ padding: paddingStyle, fontFamily: 'Sen, sans-serif' }}>
      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '20px' }}>
        {category.charAt(0).toUpperCase() + category.slice(1)} Publicaciones
      </Typography>

      <Grid container spacing={3} justifyContent="left" p="0px 0px">
        {currentPosts.map((post, index) => (
          <Grid item xs={12} sm={6} md={3} key={post._id}>
            <Card sx={{ boxShadow: 'none', borderRadius: '10px', display: 'flex', flexDirection: 'column' }}>
              <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                <CardMedia
                  component="img"
                  image={post.image || '/default-image.jpg'}
                  alt={post.title}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '10px 10px 20px 20px',
                  }}
                />
              </Link>
              <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                  <Button
                    className="card-btn"
                    style={{
                      marginBottom: '10px',
                      fontSize: '12px',
                      textAlign: 'left',
                      padding: '7px 15px 7px 10px',
                      backgroundColor: buttonColors[index % buttonColors.length], // Using buttonColors here
                      color: '#fff',
                      width: 'fit-content',
                      height: '32px',
                      clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)',
                      transition: 'all 0.3s ease-in-out',
                      display: 'inline-block',
                      borderRadius: '0px',
                    }}
                  >
                    {post.category}
                  </Button>
                </Link>
                <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h7" style={{ fontWeight: 'bold', marginBottom: '10px', color: "#212529" }}>
                    {limitTitle(post.title)} {/* Apply title truncation */}
                  </Typography>
                </Link>
                <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    alt={post.writerName || 'Unknown'}
                    src={post.writerImage || '/default-profile.png'}
                    sx={{
                      width: 30,
                      height: 30,
                      marginRight: '10px',
                      fontSize:"14px",
                      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow
                    }}
                  />
                  <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  <a style={{color:"#000", textDecoration:"none"}} href={`/autor/${post.writerName}`}>{post.writerName}</a>
                  </span>
                  <span>{formatDate(post.date || post._id)}</span> {/* Display formatted date */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <div style={{ margin: '30px', display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(posts.length / postsPerPage)}
          page={currentPage}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default SinglePage;
