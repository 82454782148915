import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, Button, CircularProgress, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
const Divertissement = () => {
  const [state, setState] = useState({
    postsData: [], // Holds posts fetched from the API
    loading: true, // Tracks loading state
    error: null,   // Holds error message in case of a fetch failure
  });

  const buttonColors = [
    '#FF5733', // Red
    '#33C4FF', // Blue
    '#28A745', // Green
    '#FF914D', // Orange
    '#F9A825', // Yellow
    '#9C27B0', // Purple
  ];

  useEffect(() => {
    // Fetch data directly from the API for "Divertissement" category
    axios.get('https://roloeganga.net/admin/api/category/news/Entretenimiento') // Fetch data for the "Divertissement" category
      .then(response => {
        const sortedPosts = response.data.news.sort((a, b) => {
          const dateA = new Date(a.date || a._id); // Fallback to `_id` if `date` is not available
          const dateB = new Date(b.date || b._id);
          return dateB - dateA; // Sort in descending order (newest first)
        });
        setState(prevState => ({
          ...prevState,
          postsData: sortedPosts, // Update with sorted data
          loading: false, // Set loading to false after fetching data
        }));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setState(prevState => ({
          ...prevState,
          error: 'Failed to load posts.', // Set error message if the fetch fails
          loading: false, // Set loading to false in case of error
        }));
      });
  }, []); // Empty dependency array ensures the fetch happens once when the component mounts
  
  // Truncate the title if it is too long
  const limitTitle = (title) => {
    if (title.length > 35) {
      return title.slice(0, 35) + '...'; // Truncate title if it's too long
    }
    return title;
  };

  // Format the date
  const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) {
      return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    return 'Invalid Date';
  };

  // Destructure state for easier access
  const { postsData, loading, error } = state;

  // Show loading spinner while posts are being fetched
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  // Show error message if there is an error
  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </div>
    );
  }

  // If no posts are found for the category, show a message
  if (postsData.length === 0) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h6" color="textSecondary">
          No posts available for the "Divertissement" category. Please check back later.
        </Typography>
      </div>
    );
  }

  // Render the posts
  return (
    <div style={{ padding: '40px 0px', fontFamily: 'Sen, sans-serif' }}>
      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '20px' }}>
      Entretenimiento Publicaciones
      </Typography>

      <Grid container spacing={3} justifyContent="left">
        {postsData.slice(0, 4).map((post, index) => (
          <Grid item xs={12} sm={6} md={3} key={post._id}>
            <Card sx={{ boxShadow: 'none', borderRadius: '10px', display: 'flex', flexDirection: 'column' }}>
              <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                <CardMedia
                  component="img"
                  image={post.image || '/default-image.jpg'}
                  alt={post.title}
                  style={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: '10px 10px 20px 20px',
                  }}
                />
              </Link>
              <CardContent style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}>
                <Link to={`/categoría/${post.category}`} style={{ textDecoration: 'none' }}>
                  <Button
                    href="/single-post-1"
                    className="card-btn"
                    style={{
                      marginBottom: '10px',
                      fontSize: '12px',
                      textAlign: 'left',
                      padding: '7px 15px 7px 10px',
                      backgroundColor: buttonColors[index % buttonColors.length], // Assign color based on index
                      color: '#fff',
                      width: 'fit-content',
                      height: '32px',
                      clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)',
                      transition: 'all 0.3s ease-in-out',
                      display: 'inline-block',
                      borderRadius: '0px',
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#1E94B3'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = buttonColors[index % buttonColors.length]}
                  >
                    {post.category}
                  </Button>
                </Link>
                <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h7" style={{ fontWeight: 'bold', marginBottom: '10px', color: "#212529" }}>
                    {limitTitle(post.title)} {/* Apply title truncation */}
                  </Typography>
                </Link>
                <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    alt={post.writerName || 'Unknown'}
                    src={post.writerImage || '/default-profile.png'}
                    sx={{
                      width: 30,       // Set a smaller width (adjust as needed)
                      height: 30,      // Set a smaller height (adjust as needed)
                      marginRight: '8px', // Adjust the margin as needed
                      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // Keep the subtle shadow
                      fontSize: "14px"   // Adjust font size for initials if any
                    }}
                  />

                  <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  <a style={{color:"#000", textDecoration:"none"}} href={`/autor/${post.writerName}`}>{post.writerName}</a>
                  </span>
                  <span>{formatDate(post.date || post._id)}</span> {/* Display formatted date */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Divertissement;
