import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import axios from "axios";
import logo from "../../assets/logo.png";
import footerpost1 from "../../assets/footer-post-1.jpg";

const Footer = () => {
  const [topPosts, setTopPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopPosts = async () => {
      try {
        const response = await axios.get(
          "https://roloeganga.net/admin/api/recent/news"
        );
        setTopPosts(response.data.news || []);
      } catch (err) {
        setError("Error al cargar publicaciones.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTopPosts();
  }, []);

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return isNaN(formattedDate.getTime())
      ? "Fecha inválida"
      : formattedDate.toLocaleDateString("es-ES", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
  };

  const limitedTopPosts = topPosts.slice(0, 2);

  return (
    <Box
      sx={{
        backgroundColor: "#121418",
        color: "#b9b9b9",
        display: "flex",
        flexDirection: "column",
        mt: 4,
        width: "100%"
      }}
    >
      <Grid
        container
        spacing={4}
        px={{ xs: 2, sm: 6 }}
        py={4}
        alignItems="flex-start" // Ensure all items align at the top
      >
        {/* Logo and About Section */}
        <Grid item xs={12} md={4}>
          <Box>
            <Link href="/">
              <img
                src={logo}
                alt="Logo"
                style={{ width: "200px", height: "auto" }}
              />
            </Link>
            <Typography
              variant="body2"
              mt={2}
              sx={{ lineHeight: 1.5, fontSize: "14px" }}
            >
              Rolo e Ganga ofrece noticias actualizadas de España en español. Obtén los últimos análisis sobre política, deportes, negocios y cultura.
            </Typography>
            <Typography
              mt={2}
              sx={{ fontSize: "14px", color: "#fff", fontWeight: 600 }}
            >
              Email:{" "}
              <Typography
                component="span"
                sx={{ fontSize: "14px", color: "#b9b9b9" }}
              >
                contact@roleganga.net
              </Typography>
            </Typography>
          </Box>
        </Grid>

        {/* Top Posts Section */}
        {/* Top Posts Section */}
<Grid item xs={12} md={4}>
  <Typography
    variant="h6"
    sx={{ fontWeight: "bold", mb: 2, color: "#fff" }}
  >
    Publicaciones destacadas
  </Typography>
  {isLoading ? (
    <Typography>Cargando...</Typography>
  ) : error ? (
    <Typography color="error">{error}</Typography>
  ) : (
    limitedTopPosts.map((post, index) => (
      <Link
        href={`/${post.slug}`}
        key={index}
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          textDecoration: "none",
          color: "#b9b9b9",
        }}
      >
        <Box
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: 2,
            overflow: "hidden",
            flexShrink: 0, // Prevents resizing of the container
            mr: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f0f0f0", // Placeholder background in case of missing image
          }}
        >
          <img
            src={post.image || footerpost1}
            alt={post.title || "Publicación"}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Ensures proper cropping
              objectPosition: "center", // Centers the image
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600, color: "#fff" }}
          >
            {post.title || "Publicación sin título"}
          </Typography>
          <Typography
            sx={{ fontSize: "14px", mt: 0.5, color: "#b9b9b9" }}
          >
            {formatDate(post.date)}
          </Typography>
        </Box>
      </Link>
    ))
  )}
</Grid>


        {/* Quick Links */}
        <Grid item xs={12} md={4}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", mb: 2, color: "#fff" }}
          >
            Enlaces Importantes
          </Typography>
          <ul style={{ padding: 0, listStyle: "none" }}>
            {["Sobre nosotros", "Contáctanos", "Política de privacidad"].map(
              (text, idx) => (
                <li key={idx} style={{ marginBottom: "10px" }}>
                  <Link
                    href={`/${text.toLowerCase().replace(/\s/g, "-")}`}
                    sx={{
                      color: "#b9b9b9",
                      textDecoration: "none",
                      "&:hover": { color: "#FF184E" },
                    }}
                  >
                    {text}
                  </Link>
                </li>
              )
            )}
          </ul>
        </Grid>
      </Grid>

      {/* Footer Bottom */}
      <Box
        sx={{
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
          py: 2,
          textAlign: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", color: "#b9b9b9" }}
        >
          © 2024 Rolo e Ganga. Reservados todos los derechos.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
