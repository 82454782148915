import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import logo from "../../assets/logo.png";

const BASE_URL = 'https://roloeganga.net';

export default function SearchAppBar() {
  const [state, setState] = React.useState({ right: false });
  const [menuItems, setMenuItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [searchQuery, setSearchQuery] = React.useState(""); // Search input state
  const navigate = useNavigate(); // useNavigate hook for programmatic navigation

  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/api/categories`);
        const categories = response.data.categories || [];
        const formattedMenuItems = categories.map(item => ({
          name: item.name,
          link: `/categoría/${item.name}`,
        }));
        setMenuItems(formattedMenuItems);
        setLoading(false);
      } catch (err) {
        setError('Failed to load categories');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const toggleDrawer = (open) => () => {
    setState({ ...state, right: open });
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // Prevent page reload
    if (searchQuery.trim()) {
      navigate(`/buscar?query=${encodeURIComponent(searchQuery)}`); // Navigate to search page with query
      setSearchQuery(""); // Clear search query after submission
    } else {
      alert("Please enter a search query.");
    }
  };

  // Check if loading
  if (loading) {
    return <div>Cargando menú...</div>;
  }

  // Check if error
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Box>
      {/* Sticky AppBar */}
      <AppBar 
        position="fixed" 
        sx={{
          backgroundColor: '#F8F8F8', 
          color: 'black', 
          boxShadow: 'none',
          zIndex: 1300, // Ensure it's above other content
          top: 0, // Ensure it's stuck at the top
          padding: '5px',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: { xs: 'flex', sm: 'none' }, flex: 1 }}>
            <a href='/'>
              <img src={logo} alt="Logo" style={{ width: 150, height: 'auto' }} />
            </a>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, flex: 1 }}>
            <a href='/'>
              <img src={logo} alt="Logo" style={{ width: 180, height: 'auto', padding: '0px 20px' }} />
            </a>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 1, justifyContent: 'center', flexWrap: 'wrap', gap: 1 }}>
            <Button color="inherit" component={Link} to="/" sx={{ fontWeight: '600', padding: '0 10px' }}>
              Hogar
            </Button>
            <Button color="inherit" component={Link} to="/noticias" sx={{ fontWeight: '600', padding: '0 10px' }}>
            Noticias
            </Button>
            {menuItems.map((item, index) => (
              <Button key={index} color="inherit" component={Link} to={item.link} sx={{ fontWeight: '590', padding: '0 10px' }}>
                {item.name}
              </Button>
            ))}
          </Box>

          {/* Search Bar */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, flex: 1, justifyContent: 'center' }}>
            <form onSubmit={handleSearchSubmit} style={{ width: '100%', maxWidth: '400px', minWidth:"150px"}}>
              <InputBase
                placeholder="Buscar…"
                value={searchQuery} 
                inputProps={{ 'aria-label': 'buscar' }}
                onChange={(e) => setSearchQuery(e.target.value)}
                startAdornment={<InputAdornment position="start"><SearchIcon sx={{ color: 'primary.main' }} /></InputAdornment>}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: '50px',
                  padding: '5px 10px',
                  width: '100%',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  transition: '0.3s',
                  '&:focus-within': {
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
                  },
                  '& input': {
                    padding: '5px',
                  }
                }}
              />
            </form>
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer(true)} sx={{ display: { xs: 'block', sm: 'none' }, fontSize: '40px' }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile */}
      <Drawer anchor="left" open={state.right} onClose={toggleDrawer(false)} sx={{ width: '250px' }}>
        <Box sx={{ width: 250, padding: '20px', height: '100vh' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton color="inherit" onClick={toggleDrawer(false)} sx={{ fontSize: '50px' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <div style={{ marginTop: "20px" }}>
          <Button fullWidth color="inherit" component={Link} to="/noticias" sx={{ fontWeight: '600', paddingLeft: '16px', marginBottom: '10px', borderBottom: '1px solid #ddd',justifyContent: 'flex-start', }}>
            Noticias
            </Button>
            {menuItems.map((item, index) => (
              <Button 
                key={index} 
                fullWidth 
                variant="text" 
                sx={{ 
                  marginBottom: '10px', 
                  color: '#000', 
                  fontWeight: 'bold', 
                  textAlign: 'left', 
                  justifyContent: 'flex-start', 
                  borderBottom: '1px solid #ddd', 
                  paddingLeft: '16px' 
                }} 
                component={Link} 
                to={item.link} 
                onClick={toggleDrawer(false)}
              >
                {item.name}
              </Button>
            ))}
          </div>
        </Box>
      </Drawer>
    </Box>
  );
}
