import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Card, CardMedia, CardContent, Typography, Button, CircularProgress, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { removeHtmlTags } from '../../helper/utils';

const Enterprise = () => {
  const [state, setState] = useState({
    postsData: [],
    loading: true,
    error: null,
  });

  const buttonColors = [
    '#FF5733', // Red
    '#33C4FF', // Blue
    '#28A745', // Green
    '#FF914D', // Orange
    '#F9A825', // Yellow
    '#9C27B0', // Purple
  ];

  const buttonStyles = {
    marginBottom: '10px',
    fontSize: '12px',
    textAlign: 'left',
    padding: '8px 16px',
    color: '#fff',
    width: 'fit-content',
    height: '36px',
    clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)',
    transition: 'all 0.3s ease-in-out',
    display: 'inline-block',
    borderRadius: '0',
    '&:hover': {
      opacity: 0.8,
    },
  };

  useEffect(() => {
    axios
      .get('https://roloeganga.net/admin/api/category/news/Negocio')
      .then((response) => {
        const sortedPosts = response.data.news.sort((a, b) => {
          const dateA = new Date(a.date || a._id);
          const dateB = new Date(b.date || b._id);
          return dateB - dateA;
        });
        setState({
          postsData: sortedPosts,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setState({
          postsData: [],
          loading: false,
          error: 'Failed to load posts.',
        });
      });
  }, []);

  const limitTitle = (title) => (title.length > 35 ? `${title.slice(0, 35)}...` : title);
  const limitDescription = (desc) => (desc && desc.length > 80 ? `${desc.slice(0, 80)}...` : desc || 'No description available.');

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    return !isNaN(formattedDate.getTime())
      ? formattedDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : 'Invalid Date';
  };

  const { postsData, loading, error } = state;

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </div>
    );
  }

  if (postsData.length === 0) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h6" color="textSecondary">
          No posts available for the "Negocio" category. Please check back later.
        </Typography>
      </div>
    );
  }

  return (
    <div style={{ padding: '40px 0', fontFamily: 'Sen, sans-serif' }}>
      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '20px', fontSize: '1.8rem' }}>
        Publicaciones Comerciales
      </Typography>

      <Grid container spacing={3} justifyContent="left">
        {postsData.slice(0, 4).map((post, index) => (
          <Grid item xs={12} sm={6} md={3} key={post._id}>
            <Card sx={{
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              '&:hover': {
                boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.15)',
              },
            }}>
              <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                <CardMedia
                  component="img"
                  image={post.image || '/default-image.jpg'}
                  alt={post.title}
                  sx={{
                    width: '100%',
                    height: '180px',
                    objectFit: 'cover',
                  }}
                />
              </Link>
              <CardContent sx={{ padding: '16px' }}>
                <Link to={`/categoría/${post.category}`} style={{ textDecoration: 'none' }}>
                  <Button
                    sx={{
                      ...buttonStyles,
                      backgroundColor: buttonColors[index % buttonColors.length],
                    }}
                  >
                    {post.category}
                  </Button>
                </Link>
                <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                  <Typography variant="h6" sx={{
                    fontWeight: 'bold',
                    marginTop: '12px',
                    fontSize: '16px',
                    color: '#333',
                    lineHeight: 1.4,
                  }}>
                    {limitTitle(removeHtmlTags(post.title))}
                  </Typography>
                </Link>
                <Typography variant="body2" color="text.secondary" sx={{
                  marginTop: '10px',
                  fontSize: '14px',
                }}>
                  {limitDescription(removeHtmlTags(post.description))}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                }}>
                  <Avatar
                    alt={post.writerName || 'Unknown'}
                    src={post.writerImage || '/default-profile.png'}
                    sx={{
                      width: 30,
                      height: 30,
                      marginRight: '8px',
                      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                  <span><a style={{color:"#000", textDecoration:"none"}} href={`/autor/${post.writerName}`}>{post.writerName}</a></span>
                  <span style={{ marginLeft: '10px' }}>{formatDate(post.date || post._id)}</span>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Enterprise;
