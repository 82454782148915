import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import { Grid, Card, CardContent, Typography, Button, Pagination, CircularProgress, Avatar } from '@mui/material';
import newpost1 from "../../assets/categori-img-1.jpg"; // Default image
import { Link } from 'react-router-dom';
const LatestPosts = () => {
  const [state, setState] = useState({
    postsData: [],
    currentPosts: [],
    loading: true,
    error: null,
    totalPages: null,
    page: 1,
  });

  useEffect(() => {
    axios.get(`https://roloeganga.net/admin/api/all/news_page?page=${state.page}&limit=${postsPerPage}`)
      .then(response => {
        const sortedPosts = response.data.news.sort((a, b) => {
          const dateA = new Date(a.date || a._id); // Fallback to `_id` if `date` is missing
          const dateB = new Date(b.date || b._id);
          return dateB - dateA; // Sort in descending order (newest first)
        });
  
        setState(prevState => ({
          ...prevState,
          postsData: sortedPosts,
          totalPages: response.data.pagination.totalPages,
          currentPosts: response.data.news,
          loading: false,
        }));
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setState(prevState => ({
          ...prevState,
          error: 'Failed to load posts.',
          loading: false,
        }));
      });
  }, [state.page]);
  

  const handleChangePage = (event, value) => {
    setState(prevState => ({
      ...prevState,
      page: value,
    }));
  };

  const postsPerPage = 4; 

  const getLimitedTitle = (title) => {
    const words = title.split(' ').slice(0, 6); // Take the first 6 words
    return words.join(' ') + (words.length < title.split(' ').length ? '...' : ''); // Add '...' if truncated
  };

  const getLimitedDescription = (description) => {
    return description.length > 200 ? description.slice(0, 200) + '...' : description;
  };

  const removeHtmlTags = (str) => {
    return str.replace(/<[^>]*>/g, ''); // Removes anything between < > (HTML tags)
  };

  const buttonColors = [
    '#FF5733',  // Red
    '#33C4FF',  // Blue
    '#28A745',  // Green
    '#FF914D',  // Orange
    '#F9A825',  // Yellow
    '#9C27B0'   // Purple
  ];
  const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) { // Check if the date is valid
      return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric', // "2024"
        month: 'long', // "November"
        day: 'numeric', // "13"
      });
    }
    return 'Invalid Date';
  };

  if (state.loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (state.error) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h6" color="error">{state.error}</Typography>
      </div>
    );
  }

  return (
    <div style={{ padding: '40px 0px', fontFamily: 'sans-serif' }}>
      <Typography
        variant="h5"
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          marginBottom: '20px',
          fontSize: '1.8rem'
        }}
      >
        Últimas Publicaciones de la Noticias
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {state.currentPosts.map((post, index) => {
          const imageUrl = post.image ? post.image : newpost1;

          return (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card sx={{ boxShadow: 'none', borderRadius: '10px', border: '1px solid gray', height: 'auto' }}>
                <Grid container spacing={2}>
                  {/* Post Image */}
                  {/* Post Image */}
<Grid
  item
  xs={12}
  sm={6}
  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
>
  <Link to={`/${post.slug}`} style={{ width: '100%' }}>
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover', // Use cover instead of contain to fill the container
        backgroundPosition: 'center', // Center the image
        height: '300px', // Adjust height to your preference
        width: '100%', // Full width
        borderRadius: '10px',
        margin: '10px', // Add margin around the image
      }}
    />
  </Link>
</Grid>

               
                  {/* Post Content */}
                  <Grid item xs={12} sm={6}>
                    <CardContent>
                      {/* Category Button */}
                    <Link to={`/categoría/${post.category}`} >
                    <Button
                        href="/"
                        className="card-btn"
                        style={{
                          marginBottom: '10px',
                          fontSize: '12px',
                          textAlign: 'left',
                          padding: '7px 15px 7px 10px',
                          backgroundColor: buttonColors[index % buttonColors.length], // Assign unique color
                          color: 'white',
                          width: 'fit-content',
                          height: '32px',
                          clipPath: 'polygon(0 0, 100% 0, 90% 100%, 0 100%)',
                          transition: 'all 0.3s ease-in-out',
                          display: 'inline-block',
                          borderRadius: '0', // Removed the border-radius for sharp corners
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = buttonColors[(index + 1) % buttonColors.length]} // Hover effect
                        onMouseLeave={(e) => e.target.style.backgroundColor = buttonColors[index % buttonColors.length]} // Reset color
                      >
                        {removeHtmlTags(post.category)} {/* Remove HTML tags from category */}
                      </Button>

                    </Link>
                      {/* Post Title */}
                    <Link to={`/${post.slug}`}style={{textDecoration:"none"}} >
                    <Typography
                        variant="h5"
                        style={{
                          fontFamily: 'sans-serif',
                          fontWeight: 'bold',
                          marginBottom: '10px',
                          fontSize: '16px',
                          color: "#212529",
                        }}
                      >
                        {getLimitedTitle(removeHtmlTags(post.title))} {/* Limit the title to 6 words and remove HTML tags */}
                      </Typography>

                    </Link>
                      {/* Writer's name with avatar and formatted date */}
                      <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          alt={removeHtmlTags(post.writerName || 'Unknown')}
                          src={post.writerImage || '/default-profile.png'}
                          sx={{
                            width: 30,
                            height: 30,
                            marginRight: '10px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow
                          }}
                        />
                        <pan style={{ marginRight: "10px", fontWeight: "bold" }}>

                        <a style={{color:"#000", textDecoration:"none"}} href={`/autor/${post.writerName}`}>{post.writerName}</a>
                        </pan>
                        <span>{formatDate(post.date || post._id)}</span> {/* Display formatted date */}{/* Display date normally */}
                      </Typography>


                      {/* Post Description */}
                      <Typography
                        variant="body1"
                        style={{
                          marginBottom: '10px',
                          marginTop: "15px",
                          fontSize: '13px',
                          fontFamily: 'sans-serif', // Applied sans-serif font
                        }}
                      >
                        {getLimitedDescription(removeHtmlTags(post.description))} {/* Limit the description to 250 characters and remove HTML tags */}
                      </Typography>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Pagination */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={state.totalPages}
          page={state.page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default LatestPosts;
