import React from 'react';
import { Container, Typography, Box, Paper, Divider, Link } from '@mui/material';

const AboutUs = () => {
  document.title = "Sobre nosotros | RoloeGanga";

  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Sobre nosotros | RoloeGanga";
  return (
    <Container maxWidth="lg" style={{ padding: '90px 50px', fontFamily: 'Inter, sans-serif', fontStyle: 'normal' }}>
      <Paper elevation={5} style={{ padding: '30px', borderRadius: '12px', backgroundColor: '#FFFFF' }}>
        <Typography variant="h6" gutterBottom align="center" style={{ fontWeight: 'bold', color: '#333' }}>
        Sobre nosotros
        </Typography>

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
          ¿Quiénes somos?
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
          Rolegana.net es una plataforma de noticias profesional. Aquí te ofrecemos contenido exclusivo e interesante que seguro disfrutarás. Nos comprometemos a traerte las mejores noticias, con especial foco en la fiabilidad y las últimas novedades en España.
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
          Trabajamos para convertir nuestra pasión por las noticias en un sitio web en línea exitoso. Esperamos que disfrutes de nuestras noticias tanto como nosotros disfrutamos ofrecerlas.
          </Typography>
        </Box>
        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />
        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
          Nuestra misión
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
          Publicaremos más artículos importantes para ti en nuestro sitio web. Te pedimos que nos brindes tu apoyo y cariño.
          </Typography>
        </Box>
        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />
        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Danos tu apoyo y amor
          </Typography>
          <Typography variant="body1" style={{ color: '#3498db', fontWeight: 'bold' }}>
            Publicaré más artículos importantes para ti en mi sitio web. Gracias por brindarme tu apoyo y cariño.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default AboutUs;
