import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Box, Typography, Card, CardContent, CardMedia, Avatar, Grid, CircularProgress } from '@mui/material';
import { removeHtmlTags } from '../../helper/utils';

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || ''; // Get query from URL
  document.title = "Buscar | RoloeGanga";

  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Buscar | RoloeGanga";
  useEffect(() => {
    const fetchResults = async () => {
      console.log("Fetching results for query:", query);
      if (!query) return;

      setLoading(true);
      try {
        console.log("Making API call to fetch news for query:", query);
        const response = await axios.get('https://roloeganga.net/admin/api/search/news', { params: { value: query } });
        console.log("API response data:", response.data);

        if (response.data && response.data.news) {
          setResults(response.data.news || []);
        } else {
          console.log("No 'news' data found in response");
          setResults([]);
        }
        setError(null);
      } catch (error) {
        console.error("Error occurred while fetching data:", error);
        setError('Failed to fetch search results.');
      } finally {
        setLoading(false);
        console.log("Finished fetching data");
      }
    };

    fetchResults(); // Fetch results when query changes
  }, [query]);

  const truncateDescription = (description) => {
    const truncated = description.length > 250 ? description.slice(0, 250) + '...' : description;
    console.log("Truncated description:", truncated);
    return truncated;
  };

  const limitTitleToSixWords = (title) => {
    const words = title.split(' ');
    const truncatedTitle = words.length > 6 ? words.slice(0, 6).join(' ') + '...' : title;
    console.log("Limited title:", truncatedTitle);
    return truncatedTitle;
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    if (!isNaN(formattedDate.getTime())) {
      return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
    return 'Invalid Date';
  };

  if (loading) {
    console.log("Loading in progress...");
    return <CircularProgress sx={{ display: 'block', margin: 'auto' }} />;
  }

  if (error) {
    console.log("Error state:", error);
    return <Typography variant="h6" color="error" align="center">{error}</Typography>;
  }

  return (
    <Container sx={{ py: 12, flexGrow:1 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary" gutterBottom>
        Resultados de la búsqueda para <span style={{ color: '#f50057' }}>"{query}"</span>
        </Typography>
      </Box>
      {results.length === 0 ? (
        <Typography variant="h6" color="textSecondary" align="center">
          No se encontraron resultados.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {results.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.id}>
              <Link to={`/${item.slug}`} style={{ textDecoration: 'none' }}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <CardMedia
                    component="img"
                    alt={item.title}
                    image={item.image || 'default-image.jpg'}
                    sx={{ height: 200, objectFit: 'cover' }}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      {limitTitleToSixWords(item.title)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {removeHtmlTags(truncateDescription(item.description))}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        alt={item.writerName || 'Unknown'}
                        src={item.writerImage || '/default-profile.png'}
                        sx={{
                          width: 40,
                          height: 40,
                          marginRight: '10px',
                          boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow
                        }}
                      />
                      <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                        {item.writerName || 'Unknown'}
                      </span>
                      <span>{formatDate(item.date || item._id)}</span>
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default SearchResults;
