import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Button, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import defaultImage from "../../assets/categori-img-1.jpg"; 

const Posts = () => {
  const [postsData, setPostsData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get('https://roloeganga.net/admin/api/category/news/Deportes')
      .then(response => {
        const sortedPosts = response.data.news.sort((a, b) => {
          const dateA = new Date(a.date || a._id); // Fallback to `_id` if `date` is not available
          const dateB = new Date(b.date || b._id);
          return dateB - dateA; // Sort in descending order (newest first)
        });
        setPostsData(sortedPosts); // Update with sorted data
        setLoading(false); 
      })
      .catch(error => {
        setError('Failed to load posts.');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </div>
    );
  }

  const truncateText = (text, length) => {
    return text.length > length ? `${text.slice(0, length)}...` : text;
  };

  return (
    <div style={{ padding: '40px 20px', fontFamily: "Roboto, sans-serif" }}>
      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'left', fontSize: '1.5rem' }}>
        Publicaciones Deportivas
      </Typography>
      <Grid container spacing={3} justifyContent="start">
        {postsData.slice(0, 4).map((post, index) => {
          const imageUrl = post.image ? post.image : defaultImage;
          return (
            <Grid item xs={12} sm={6} md={3} key={index}> 
              <Card sx={{ boxShadow: 3, borderRadius: '12px', position: 'relative', overflow: 'hidden' }}>
                <a href={`/${post.slug}`}>
                <CardMedia
                  component="img"
                  image={imageUrl}
                  alt={post.title}
                  style={{
                    width: '100%',
                    height: '270px',
                    objectFit: 'cover',
                    borderRadius: '12px 12px 0 0',
                  }}
                />
                {/* Overlay */}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.4)',
                    borderRadius: '10px',
                  }}
                /></a>
                <CardContent
                  style={{
                    position: 'absolute',
                    bottom: '15px',
                    left: '20px',
                    right: '20px',
                    color: 'white',
                    padding: '10px',
                    textAlign: 'center',
                    zIndex: 1, // Ensure text is above the overlay
                  }}
                >
                  <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '14px' }}>
                    {truncateText(post.category, 15)}
                  </Typography>
                  <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                    <Typography variant="h6" style={{ fontWeight: '300', marginBottom: '10px', fontSize: '13px', color: "#FFFFFF" }}>
                      {truncateText(post.title, 40)} {/* Display the title with truncation if needed */}
                    </Typography>
                  </Link>
                  <Link to={`/${post.slug}`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: 'white',
                        color: 'white',
                        fontSize: '12px',
                        padding: '6px 12px',
                        borderWidth: '1px',
                        backgroundColor: 'transparent',
                        marginTop: '10px',
                        borderRadius: '30px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: 'black',
                        },
                      }}
                    >
                      Leer Más
                    </Button>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Posts;
