import React from 'react';
import { Container, Typography, Box, Paper, Divider, Link } from '@mui/material';

const PrivacyPolicy = () => {
  document.title = "Política de Privacidad | RoloeGanga";

  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Política de Privacidad | RoloeGanga";
  return (
    <Container maxWidth="lg" style={{ padding: '90px 50px', fontFamily: 'Inter, sans-serif', fontStyle: 'normal' }}>
      <Paper elevation={5} style={{ padding: '30px', borderRadius: '12px', backgroundColor: '#FFFFF' }}>
        <Typography variant="h5" gutterBottom align="center" style={{ fontWeight: 'bold', color: '#333' ,marginBottom:"40px"}}>
          Política de Privacidad
        </Typography>

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Quiénes somos
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            La dirección de nuestro sitio web es: <Link href="https://roloeganga.net" style={{ color: "#3498db", textDecoration: "none", fontWeight: "550" }}>
              https://roloeganga.net
            </Link>.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Comentarios de los usuarios
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Cuando los visitantes dejan comentarios en nuestro sitio, recopilamos la información proporcionada en el formulario, así como la dirección IP del usuario y el agente de usuario del navegador para ayudar a detectar posibles comentarios de spam.
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            También podemos enviar una cadena anonimizada de tu dirección de correo electrónico (un hash) al servicio Gravatar para comprobar si lo estás utilizando. Puedes consultar la política de privacidad de Gravatar aquí:
            <Link href="https://automattic.com/privacy/" style={{ color: "#3498db", textDecoration: "none", fontWeight: "550" }}>
              https://automattic.com/privacy
            </Link>.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Archivos multimedia
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Si subes imágenes a nuestro sitio, te recomendamos evitar cargar imágenes que contengan metadatos de ubicación (como los datos EXIF GPS). Los visitantes de nuestro sitio pueden extraer estos datos de ubicación de las imágenes cargadas.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Uso de cookies
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Al dejar un comentario en nuestro sitio, puedes optar por guardar tu nombre, correo electrónico y página web en cookies. Esto tiene como fin facilitar tu próxima visita, evitando que tengas que volver a ingresar tus datos. Estas cookies tienen una duración de un año.
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Al acceder a nuestra página de inicio de sesión, se configurará una cookie temporal para verificar si tu navegador acepta cookies. Esta cookie no contiene datos personales y se elimina al cerrar el navegador.
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Cuando inicies sesión, también se crearán varias cookies para almacenar tu información de inicio de sesión y tus preferencias de visualización. Las cookies de inicio de sesión duran dos días, mientras que las preferencias de visualización se almacenan por un año. Si seleccionas "Permanecer conectado", tu sesión se mantendrá durante dos semanas. Las cookies de inicio de sesión se eliminan al cerrar sesión.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Contenido incrustado de otros sitios
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Los artículos de este sitio pueden incluir contenido incrustado, como videos, imágenes o artículos de otros sitios web. Este contenido se comporta de la misma forma que si el visitante hubiera visitado directamente el otro sitio.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            ¿Con quién compartimos tus datos?
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Si solicitas un restablecimiento de contraseña, tu dirección IP será incluida en el correo electrónico de restablecimiento.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            ¿Por cuánto tiempo conservamos tus datos?
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Los comentarios y sus metadatos se conservan de manera indefinida. Esto nos permite aprobar automáticamente los siguientes comentarios sin tener que pasarlos por un proceso de moderación.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Tus derechos
          </Typography>
          <Typography variant="body1" paragraph style={{ color: '#555' }}>
            Si tienes una cuenta en nuestro sitio o has dejado comentarios, puedes solicitar un archivo con los datos personales que tenemos sobre ti, incluidos todos los detalles que nos hayas proporcionado.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            ¿A dónde se envían tus datos?
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Los comentarios de los visitantes pueden ser verificados mediante un servicio automatizado para detectar spam.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Cambios a esta política de privacidad
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Los cambios serán reflejados en esta página con la fecha de "Última actualización" correspondiente.
          </Typography>
        </Box>

        <Divider style={{ margin: '20px 0', backgroundColor: '#e0e0e0' }} />

        <Box marginBottom={4} style={{ borderLeft: '4px solid #3498db', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom style={{ color: '#2C3E50', fontWeight: '700' }}>
            Contáctanos
          </Typography>
          <Typography variant="body1" style={{ color: '#555' }}>
            Si tienes preguntas o inquietudes acerca de esta política de privacidad, no dudes en ponerte en contacto con nosotros en     
            <Link href="mailto:contact@roloeganga.net" style={{ color: "#3498db", textDecoration: "none", fontWeight: "550", marginLeft: '5px' }}>
               contact@roloeganga.net
            </Link>.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
