import React, { useEffect, useState } from 'react';
import Divertissement from './Categorys';
import MyComponent from './Herosection';
import LatestPosts from './LatestPosts';
import Posts from './Posts';
import Enterprice from "./Enterprice";
import axios from "axios";

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initially check if it's a mobile screen
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios
      .get(`https://roloeganga.net/admin/api/meta`)
      .then((response) => {
        if (response.data) {
          setNewsDetail(response.data);
        } else {
          setNewsDetail(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
        setError("Failed to fetch news details.");
      });
  }, []); // Empty dependency array ensures the effect runs only once

      useEffect(() => {
        if (newsDetail) {
          // Set the document title
        document.title = newsDetail.meta_title || "RoloeGanga";
    
        // Check if the meta title already exists
        let metaTitleTag = document.querySelector('meta[name="title"]');
        if (!metaTitleTag) {
          // Create and append the meta title if it does not exist
          metaTitleTag = document.createElement("meta");
          metaTitleTag.name = "title";
          document.head.appendChild(metaTitleTag);
        }
        metaTitleTag.content = newsDetail.meta_title || "RoloeGanga";
    
        // Check if the meta description already exists
        let metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (!metaDescriptionTag) {
          // Create and append the meta description if it does not exist
          metaDescriptionTag = document.createElement("meta");
          metaDescriptionTag.name = "description";
          document.head.appendChild(metaDescriptionTag);
        }
        metaDescriptionTag.content = newsDetail.meta_description || "No description available.";
      }
      }, [newsDetail]);
    
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Set padding conditionally based on the screen size
  const paddingStyle = isMobile ? '35px 25px' : '30px 100px';

  return (
    <div style={{ padding: paddingStyle, fontFamily: 'Sen, sans-serif', paddingTop: '70px' }}>
      <MyComponent />
      <Divertissement />
      <Posts />
      <Enterprice />
      <LatestPosts />
    </div>
  );
}

export default Home;
